import { RedirectUrlModel } from '../data/builderio/url-redirects/redirect-url-model';
import { LocalRoutesEnum } from './constants';

/**
 *
 * @param localRoutes
 * @returns Removed leading slashes from the paths in LocalRoutesEnum
 */
export const removeLeadingSlashes = (localRoutes: typeof LocalRoutesEnum) => {
  const cleanedRoutes = { ...localRoutes };
  for (const key in cleanedRoutes) {
    cleanedRoutes[key as keyof typeof LocalRoutesEnum] = cleanedRoutes[
      key as keyof typeof LocalRoutesEnum
    ].replace('/', '');
  }

  return cleanedRoutes;
};

/**
 * @returns Regex to test if current path match with blog or press for example /blog/:id or /press-releases/:id
 */
export const routePattern = () => {
  const formattedLocalRoutes = removeLeadingSlashes(LocalRoutesEnum);
  const routeValues = Object.values(formattedLocalRoutes).join('|');
  return new RegExp(`^/(${routeValues})/[a-zA-Z0-9-]+$`);
};

/**
 * @param pathPattern
 * @returns function to check if a given URL matches a specific path pattern
 */
export const createPathChecker = (pathPattern: string) => {
  const normalizedPath = pathPattern.startsWith('/')
    ? pathPattern
    : '/' + pathPattern;
  const escapedPath = normalizedPath.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  const pathRegex = new RegExp(`^${escapedPath}(/|$|/.*)`);

  return function doesPathMatch(urlToCheck: string) {
    return pathRegex.test(new URL(urlToCheck).pathname);
  };
};

/**
 * @param redirect
 * @param currentUrlPath
 * @returns function to check if the current URL matches the specified redirect source URL path
 */

export const doesCurrentUrlMatchRedirect = (
  redirect: RedirectUrlModel,
  currentUrlPath: string,
) => {
  const pathToCheck = new URL(redirect.data?.sourceUrl as string).pathname;
  const currentURL = new URL(currentUrlPath, window.location.origin);
  const pathChecker = createPathChecker(pathToCheck);
  return pathChecker(currentURL.toJSON());
};

/**
 * @param redirect
 * @returns destination URL from the provided RedirectUrlModel
 */
export const generateDestinationUrl = (redirect: RedirectUrlModel) => {
  const pathName = new URL(redirect.data?.destinationUrl as string);
  return new URL(pathName.pathname as string, window.location.origin).toJSON();
};
