export const configs = {
  BUILDER_PUBLIC_API_KEY: process.env.REACT_APP_BUILDER_PUBLIC_API_KEY || '',
  MARKETING_API_BASE_URL: process.env.REACT_APP_MARKETING_API_BASE_URL || '',
  SID_WEBSITE_BASE_URL: process.env.REACT_APP_SID_WEBSITE_BASE_URL || '',
};

export enum BuilderModels {
  // Page models
  PAGE = 'loan-page',
  // Global
  TOP_BAR_LINK = 'loan-topbar-link',
  FOOTER_LINK = 'loan-footer-link',
  // Testimonial
  TESTIMONIAL = 'testimonial',
  // Offering Table
  OFFERING_GROUP = 'offering-group',
  OFFERING_FEATURE = 'offering-feature',
  // Url Redirects
  URL_REDIRECTS = 'url-redirects',
  // Banner model
  BANNER = 'banner',
}

export enum BuilderComponents {
  // Accordion
  ACCORDION = 'Accordion',
  // Fqs Accordion
  FAQS_ACCORDION = 'Faqs Accordion',
  // Blog
  BLOG_ARTICLE_QUOTE = 'Blog Article Quote',
  BLOG_ARTICLE_SUBTITLE = 'Blog Article Subtitle',
  BLOG_ARTICLE_TEXT = 'Blog Article Text',
  // Markets
  MARKETS_SWITCHER = 'Markets Switcher',
  // Offering Table
  OFFERING_TABLE = 'Offering Table',
  // PopUp Form
  POPUP_FORM = 'Popup Form',
  // Related Articles
  BLOG_ARTICLES_BY_AUDIENCE = 'Blog Articles by Audience',
  BLOG_ARTICLES_BY_ID = 'Blog Articles by ID',
  BLOG_ARTICLES_BY_TAG = 'Blog Articles by Tag',
  // Testimonial
  TESTIMONIAL = 'Testimonial',
  TESTIMONIALS_SLIDER = 'Testimonials Slider',
  // Featured Offer
  FEATURED_OFFER = 'Featured Offer',
  FEATURED_OFFER_BOX = 'Featured Offer Box',
  FEATURED_OFFER_SLIDER = 'Featured Offers Slider',
  // Button
  BUTTON = 'Button',
  // Consent Modal
  PRIMARY_MARKET_CONSENT_MODAL = 'Primary Market Consent Modal',
  // Market Car
  MARKET_CARD = 'Market Card',
}

export const LocalRoutesEnum = {
  BLOG: '/learn/blog',
  PRESS: '/learn/press',
  FAQS: '/learn/faqs',
};

export const footerConstants = {
  COPYRIGHT: 'SZ Credit Rewards Limited',
  EMAIL_CONTACT: 'creditops@securitize.io',
};
