import { BuilderComponent } from '@builder.io/react';
import { BuilderModels } from '../../helpers/constants';
import { usePageStore } from '../../store/page/page.store';

export const Page = () => {
  const { content } = usePageStore();

  return (
    content && (
      <div className="mkt-min-h-screen mkt-pt-[-88px]">
        <BuilderComponent model={BuilderModels.PAGE} content={content} />
      </div>
    )
  );
};
